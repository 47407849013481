import { dynamicNamespace, equals, mergeFilters, select } from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_USER_EMPLOYEE_PROFILE_PERMISSIONS,
  IUserEmployeeProfilePermissions,
  UserEmployeeProfilePermissions,
} from './models';

export * from './models';

type Model = UserEmployeeProfilePermissions;
const REVALIDATE_TAG = 'UserEmployeeProfilePermissions';

type Source = Pick<Model, 'id' | 'title'>;
class Service extends DynamicService<Model> {}

export const ServiceUserEmployeeProfilePermissions = new Service({
  getAll: API_USER_EMPLOYEE_PROFILE_PERMISSIONS.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILE_PERMISSIONS.POST,
  patch: API_USER_EMPLOYEE_PROFILE_PERMISSIONS.PATCH,
  delete: API_USER_EMPLOYEE_PROFILE_PERMISSIONS.DELETE,
});

const dynamic = dynamicNamespace<IUserEmployeeProfilePermissions>();

export const apiUserEmployeeProfilePermissions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserEmployeeProfilePermissionSource: build.query<Source[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceUserEmployeeProfilePermissions.getAllDynamic<Source>({
            select: select('id', 'title'),
            filter: mergeFilters(dynamic.makeFilter(['isActive'], true, equals)).join('&&'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserEmployeeProfilePermission: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceUserEmployeeProfilePermissions.patch(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
  }),
});
