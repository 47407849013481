import { endOfYear, startOfYear } from 'date-fns';
import { IncomeAccounts } from 'services/income-accounts';
import { PaymentType } from 'services/payment-types';
import {
  IUserPharmacistProfileInvoices,
  UserPharmacistProfileInvoices,
} from 'services/user-pharmacist-profile-invoices';
import { IUserPharmacistProfile } from 'services/user-pharmacist-profiles';
import { DateValue } from 'utils/dates';
import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';

const API = 'UserPharmacistProfileInvoiceDetails';

export const API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<UserPharmacistProfileInvoiceDetails, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPharmacistProfileInvoiceDetails, 'id'>) => `${API}/Delete/${data.id}`,
};
export const schemaUserPharmacistProfileInvoiceDetails = yup.object({
  quantity: yup.number().integer('rule-integer').required('rule-required'),
  paymentTypeID: yup.string().required('rule-required'),
  incomeAccountID: yup.string().required('rule-required'),
  amount: yup.number().required('rule-required'),
  userPharmacistProfileID: yup.string().required('rule-required'),
  remarks: yup.string().required('rule-required'),
});
export class UserPharmacistProfileInvoiceDetails
  implements yup.InferType<typeof schemaUserPharmacistProfileInvoiceDetails>
{
  id!: string;
  rowIndex!: number;
  isActive: boolean = true;
  userPharmacistProfileID!: string;
  paymentTypeID!: string;
  incomeAccountID!: string;
  invoiceNumber = '';
  invoiceDate = new Date().toISOString();
  quantity = 1;
  amount = 0;
  fromDate = new Date();
  toDate = new Date();
  remarks = '';
  paidBy = '';
  score = '';
  status = '';
  quality = '';
  duration = 0;
  contribution = '';
  sector = '';
  userPharmacistProfileInvoiceID = '';
  discount?: number | string;
  discountPercentage?: number | string;
}
export const schemaUserPharmacistProfileInvoiceDetailsExportToExcel = yup.object({
  quantity: yup.number().integer('rule-integer').required('rule-required'),
  paymentTypeID: yup.string().required('rule-required'),
  incomeAccountID: yup.string().required('rule-required'),
  amount: yup.number().required('rule-required'),
  userPharmacistProfileID: yup.string().required('rule-required'),
});
export class UserPharmacistProfileInvoiceDetailsExportToExcel
  implements yup.InferType<typeof schemaUserPharmacistProfileInvoiceDetailsExportToExcel>
{
  id!: string;
  userPharmacistProfileID!: string;
  rowIndex!: number;
  paymentTypeID: string = '7a541852-98dd-4179-a86f-ea307b87da11';
  incomeAccountID: string = 'b894fa2a-d43b-4bea-9e61-8aecba41ac4c';
  quantity = 1;
  amount = 0;
  fromDate = startOfYear(new Date());
  toDate = endOfYear(new Date());
  file = '';
  userPharmacistProfileInvoiceID = '';
  discount?: number | string;
  discountPercentage?: number | string;
  remarks = '';
  duration = 12;
}
export interface IUserPharmacistProfileInvoiceDetails extends UserPharmacistProfileInvoiceDetails {
  userPharmacistProfileInvoice: IUserPharmacistProfileInvoices;
  userPharmacistProfile: IUserPharmacistProfile;
  paymentType: PaymentType;
  incomeAccount: IncomeAccounts;
  accountCode: string;
  discountPercentage: string;
  discountAmount: number;
  'userPharmacistProfileInvoice.invoiceType.step': string;
}
export interface IUserPharmacistProfilePaymentSubscription
  extends Pick<IUserPharmacistProfileInvoiceDetails, 'fromDate' | 'toDate'> {
  paymentTypeTitle: string;
}
export interface IGridUserPharmacistProfileInvoiceDetails
  extends Pick<
    IUserPharmacistProfileInvoiceDetails,
    | 'id'
    | 'invoiceDate'
    | 'invoiceNumber'
    | 'quantity'
    | 'amount'
    | 'fromDate'
    | 'toDate'
    | 'remarks'
    | 'userPharmacistProfileInvoiceID'
    | 'discountPercentage'
    | 'discountAmount'
  > {
  paymentTypeTitle: string;
  paymentTypeID: string;
  incomeAccountID: string;
  userPharmacistProfileID: string;
  appIdentityUserID: string;
  paidBy: string;
  userPharmacistProfileFullName: string;
  incomeAccountCode: string;
  incomeTitle: string;
  userPharmacistProfilePaidByName: string;
  accountCode: string;
  incomeAccountTitle: string;
  showRemarks: boolean;
  rowNumber: number;
  pharmacistProfileInvoiceID: string;
}

export interface UserPharmacistProfileInvoiceDetailsDuplicateInput {
  id: string;
}
export interface UserPharmacistProfileInvoiceDetailsDuplicateGet
  extends Pick<
    UserPharmacistProfileInvoiceDetails,
    | 'isActive'
    | 'userPharmacistProfileInvoiceID'
    | 'userPharmacistProfileID'
    | 'paymentTypeID'
    | 'incomeAccountID'
    | 'quantity'
    | 'amount'
    | 'fromDate'
    | 'toDate'
    | 'score'
    | 'status'
    | 'quality'
    | 'duration'
    | 'contribution'
    | 'sector'
    | 'remarks'
  > {
  accountCode: string;
  discountPercentage: number;
  discountAmount: number;
  userPharmacistProfileInvoice: Pick<UserPharmacistProfileInvoices, 'userPharmacistProfileID'>;
}

export interface IGridPharmacistsActivities
  extends Pick<
    IUserPharmacistProfileInvoiceDetails,
    'id' | 'quantity' | 'amount' | 'fromDate' | 'toDate'
  > {
  invoiceNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['invoiceNumber'];
  paymentDate: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['paymentDate'];
  paidBy: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfile']['fullName'];
  paidByProfileID: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfileID'];
  idNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfile']['idNumber'];
  paidFor: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['fullName'];
  phoneMobile: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['phoneMobile'];
  email: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['email'];
  paymentTypeTitle: IUserPharmacistProfileInvoiceDetails['paymentType']['title'];
  incomeAccountTitle: IUserPharmacistProfileInvoiceDetails['incomeAccount']['title'];
  invoiceDate: string;
  invoiceTypeTitle: string;
  userPharmacistProfileID: string;
  paidForIdNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['idNumber'];
  pharmacistProfileInvoiceID: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['id'];
}

export interface IGridPharmacistsActivitiesFilters {
  search: string;
  paidByID: string;
  paidForID: string;
  invoiceNumber: string;
  incomeAccountTitle: string;
  paymentTypeTitle: string;
  date: DateValue[];
  isActiveOnly: boolean;
}
export interface IGridPharmacistsActivitiesArgs extends IGridPharmacistsActivitiesFilters {
  take: number;
  skip: number;
  order: DynamicOrder;
}

export interface AvailableInvoiceNumbersSource {
  id: string;
  title: string;
}

export interface IGridYearlySubscriptions
  extends Pick<IUserPharmacistProfileInvoiceDetails, 'id' | 'quantity' | 'amount'> {
  invoiceNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['invoiceNumber'];
  toDate: IUserPharmacistProfileInvoiceDetails['toDate'];
  fromDate: IUserPharmacistProfileInvoiceDetails['fromDate'];
  paidBy: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfile']['fullName'];
  paidByProfileID: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfileID'];
  idNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['userPharmacistProfile']['idNumber'];
  paidFor: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['fullName'];
  phoneMobile: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['phoneMobile'];
  email: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['email'];
  paymentTypeTitle: IUserPharmacistProfileInvoiceDetails['paymentType']['title'];
  incomeAccountTitle: IUserPharmacistProfileInvoiceDetails['incomeAccount']['title'];
  invoiceDate: string;
  invoiceTypeTitle: string;
  userPharmacistProfileID: string;
  paidForIdNumber: IUserPharmacistProfileInvoiceDetails['userPharmacistProfile']['idNumber'];
  pharmacistProfileInvoiceID: IUserPharmacistProfileInvoiceDetails['userPharmacistProfileInvoice']['id'];
}

export interface IGridYearlySubscriptionsFilters {
  search: string;
  paidByID: string;
  paidForID: string;
  paymentTypeID: string[];
  year: string;
  isActiveOnly: boolean;
  isEmptyOnly: boolean;
}
export interface IGridYearlySubscriptionsArgs extends IGridYearlySubscriptionsFilters {
  take: number;
  skip: number;
  order: DynamicOrder;
}
