import { endOfYear, format, startOfYear } from 'date-fns';
import { convertToDate, isDateLike } from 'utils/dates';
import { Paths } from 'utils/types';

type FilterVal = string | undefined | null | boolean;
type FilterPaths<T> = keyof T | string;
class MergeFilters {
  private value: Array<FilterVal>;
  constructor(value: Array<FilterVal>) {
    this.value = value.filter(Boolean);
  }
  join(separator: string) {
    if (this.value.length === 0) {
      return undefined;
    }
    return createStart(this.value.length, this.value.join(separator));
  }
}

type ValueCreator = (name: string, value: any | any[]) => string | undefined;

export const select = (...args: string[]) => {
  return args.join(',');
};

export interface DynamicOrder<F extends string = string> {
  field: F;
  order: 'desc' | 'asc' | null;
}
export const orderBy = (name: string, order?: 'desc' | 'asc' | null) => {
  if (!order) {
    return undefined;
  }
  const names = name.split(',');
  return names.map((name) => [name, order].join(' ')).join(',');
};

const prepareValue = (value?: string) => {
  return String(value).trim().replace(/"/gi, '\\"');
};
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query === '' ? undefined : query;
};

export const makeFilter = <T extends Record<string, any>>(
  name: Paths<T, 5> | Paths<T, 5>[],
  value: any | any[],
  valueCreator: ValueCreator,
): string | undefined => {
  const names = Array.isArray(name) ? name : [name];

  if (value === undefined) {
    return undefined;
  }

  const values = names.map((_n) => valueCreator(String(_n), value)).filter(Boolean);

  return createStart(values.length, values.join('||'));
};

export const dynamicNamespace = <T extends Record<string, any>>() => {
  return {
    select: (...args: string[]) => select(...args),
    makeFilter: (
      name: Paths<T, 5> | Paths<T, 5>[],
      value: any | any[],
      valueCreator: ValueCreator,
    ) => makeFilter<T>(name, value, valueCreator),
    orderBy: (name: string, order?: 'desc' | 'asc' | null) => orderBy(name, order),
  };
};

export const mergeFilters = (...filters: FilterVal[]) => {
  return new MergeFilters(filters);
};

// Value creators
export const more: ValueCreator = (name, value) => {
  if (!value) return undefined;

  return `${name}>${value}`;
};
export const moreOrEquals: ValueCreator = (name, value) => {
  if (!value) return undefined;

  return `${name}>=${value}`;
};
export const less: ValueCreator = (name, value) => {
  if (!value) return undefined;
  return `${name}<${value}`;
};
export const lessOrEquals: ValueCreator = (name, value) => {
  if (!value) return undefined;
  return `${name}<=${value}`;
};
export const equals: ValueCreator = (name, value) => {
  let innerValue = value;

  if (typeof value === 'string') {
    innerValue = `"${prepareValue(value)}"`;
  }
  return `${name}==${innerValue}`;
};
export const notEquals: ValueCreator = (name, value) => {
  let innerValue = value;

  if (typeof value === 'string') {
    innerValue = `"${prepareValue(value)}"`;
  }
  return `${name}!=${innerValue}`;
};
export const contains: ValueCreator = (name, value) => {
  if (!value) return undefined;

  return `${name}.contains("${prepareValue(String(value))}")`;
};
export const dateRange: ValueCreator = (name, value) => {
  if (!Array.isArray(value)) return undefined;

  const range: any[] = value.filter(isDateLike);

  if (range.length < 2) {
    return undefined;
  }
  const [start, end] = range;
  return [
    `(${name} >= DateTime(${format(convertToDate(start), 'yyyy,MM,dd')})`,
    `${name} <= DateTime(${format(convertToDate(end), 'yyyy,MM,dd')}))`,
  ].join('&&');
};

export const numberDateRange: ValueCreator = (name, value) => {
  const getYearDateRange = (year: string) => {
    const startDate = startOfYear(new Date(Number(year), 0, 1));
    const endDate = endOfYear(new Date(Number(year), 11, 31));

    return [startDate, endDate];
  };
  const updatedValue = getYearDateRange(value);
  if (!Array.isArray(updatedValue)) return undefined;

  const range: any[] = updatedValue.filter(isDateLike);

  if (range.length < 2) {
    return undefined;
  }
  const [start, end] = range;
  return [
    `(${name} >= DateTime(${format(convertToDate(start), 'yyyy,MM,dd')})`,
    `${name} <= DateTime(${format(convertToDate(end), 'yyyy,MM,dd')}))`,
  ].join('&&');
};

// decorators

export const decoratorIsNotNullable = (creator: ValueCreator): ValueCreator => {
  const result: ValueCreator = (name, value) => {
    if ([value === null, value === ''].some(Boolean)) return undefined;
    return creator(name, value);
  };
  return result;
};
export const decoratorIsNumber = (creator: ValueCreator): ValueCreator => {
  const result: ValueCreator = (name, value) => {
    if ([!isFinite(value as any)].some(Boolean)) return undefined;
    return creator(name, value);
  };
  return decoratorIsNotNullable(result);
};
export const decoratorValueArray = (creator: ValueCreator, separator = '||') => {
  return ((name, value) => {
    if (!Array.isArray(value)) return undefined;
    if (value.length === 0) return undefined;
    return createStart(
      value.length,
      value.map((v) => makeFilter(name, v, creator)).join(separator),
    );
  }) as ValueCreator;
};
export const decoratorExclude = (creator: ValueCreator, excludeValue: any) => {
  return ((name, value) => {
    if (value === excludeValue) return undefined;
    return creator(name, value);
  }) as ValueCreator;
};

export const createFilterContains = <T extends object = {}>(
  name: FilterPaths<T> | FilterPaths<T>[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? `(${names
        .map((_n) => `${_n.toString()}.ToLower().contains("${prepareValue(value)}")`)
        .join('||')})`
    : null;
};

export const createFilterEquals = <T extends object = {}>(
  name: FilterPaths<T> | FilterPaths<T>[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? createStart(
        names.length,
        names.map((_n) => `${_n.toString()}=="${prepareValue(value)}"`).join('||'),
      )
    : undefined;
};

export const createFilterNotEquals = <T extends object = {}>(
  name: FilterPaths<T> | FilterPaths<T>[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return createStart(
    names.length,
    names.map((_n) => `${_n.toString()}!="${prepareValue(value)}"`).join('||'),
  );
};

export const createFilterContainsPartial = <T extends object = {}>(
  name: Paths<T, 5> | Paths<T, 5>[],
  value: any,
) => {
  if (!value) return null;

  const words = String(value).split(' ').filter(Boolean);

  if (words.length === 0) return null;

  return mergeFilters(...words.map((word) => createFilterContains(name, word))).join('&&');
};
