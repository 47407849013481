import { select } from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import { API_LABEL_SOURCES, LabelSource } from './models';

export * from './models';

class Service extends DynamicService<LabelSource> {}

export const ServiceLabelSources = new Service({
  getAll: API_LABEL_SOURCES.GET_ALL_DYNAMIC,
  post: API_LABEL_SOURCES.POST,
  patch: API_LABEL_SOURCES.PATCH,
  delete: API_LABEL_SOURCES.DELETE,
});

export const apiLabelSources = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllLabelSources: build.query<LabelSource[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceLabelSources.getAllDynamic({
            select: select('id', 'key'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
