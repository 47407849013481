import { endOfYear, format, startOfYear } from 'date-fns';
import { convertToDate } from 'utils/dates';
import {
  contains,
  dateRange,
  decoratorIsNotNullable,
  decoratorValueArray,
  dynamicNamespace,
  DynamicOrder,
  equals,
  mergeFilters,
  more,
  notEquals,
} from 'utils/dynamic';
import { setToModel } from 'utils/other';
import {
  apiRtk,
  DynamicResult,
  DynamicService,
  transformResponseDynamicItemMaybe,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { ServiceUserPharmacistProfiles } from '../user-pharmacist-profiles';
import {
  API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS,
  AvailableInvoiceNumbersSource,
  IGridPharmacistsActivities,
  IGridPharmacistsActivitiesArgs,
  IGridUserPharmacistProfileInvoiceDetails,
  IGridYearlySubscriptions,
  IGridYearlySubscriptionsArgs,
  IUserPharmacistProfileInvoiceDetails,
  IUserPharmacistProfilePaymentSubscription,
  UserPharmacistProfileInvoiceDetails,
  UserPharmacistProfileInvoiceDetailsDuplicateGet,
  UserPharmacistProfileInvoiceDetailsDuplicateInput,
} from './models';

const dynamic = dynamicNamespace<IUserPharmacistProfileInvoiceDetails>();

const REVALIDATE_TAG = 'UserPharmacistProfileInvoiceDetails' as const;

export * from './models';

class Service extends DynamicService<UserPharmacistProfileInvoiceDetails> {
  async duplicate(input: UserPharmacistProfileInvoiceDetailsDuplicateInput) {
    const { data } = await this.getDynamic<UserPharmacistProfileInvoiceDetailsDuplicateGet>(
      input.id,
      {
        select: dynamic.select(
          'isActive',
          'userPharmacistProfileInvoiceID',
          'userPharmacistProfileID',
          'paymentTypeID',
          'incomeAccountID',
          'quantity',
          'amount',
          'fromDate',
          'toDate',
          'score',
          'status',
          'quality',
          'duration',
          'contribution',
          'sector',
          'remarks',

          'accountCode',
          'discountPercentage',
          'discountAmount',
          `new { userPharmacistProfileInvoice.userPharmacistProfileID } as userPharmacistProfileInvoice`,
        ),
      },
    );

    const { userPharmacistProfileInvoice, ...originData } = data;

    return this.post(
      setToModel(new UserPharmacistProfileInvoiceDetails(), {
        ...originData,
        id: undefined,
        userPharmacistProfileID: userPharmacistProfileInvoice.userPharmacistProfileID,
      }),
    );
  }
}

export const ServiceUserPharmacistProfilePayments = new Service({
  getAll: API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS.GET_ALL_DYNAMIC,
  post: API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS.POST,
  patch: API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS.PATCH,
  delete: API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS.DELETE,
});

export interface IGridUserPharmacistProfilePaymentsPaidByParams {
  search: string;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
  userPharmacistProfileID: string;
}

export interface IGridUserPharmacistProfilePaymentDetailsParams {
  search: string;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
  userPharmacistProfileInvoiceID: string;
}

export const apiUserPharmacistProfilePayments = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserPharmacistProfilePaymentLatest: build.query<
      Pick<IUserPharmacistProfileInvoiceDetails, 'invoiceDate'>,
      string
    >({
      query: (userPharmacistProfileID) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('userPharmacistProfileInvoice.invoiceDate as invoiceDate'),
          filter: mergeFilters(
            dynamic.makeFilter('userPharmacistProfileID', userPharmacistProfileID, equals),
          ).join('&&'),
          orderBy: 'userPharmacistProfileInvoice.invoiceDate',
          take: 1,
        },
      }),
      transformResponse: transformResponseDynamicItemMaybe,
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserPharmacistProfilePaymentsSubscription: build.query<
      IUserPharmacistProfilePaymentSubscription,
      string
    >({
      query: (userPharmacistProfileID) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('fromDate', 'toDate', 'paymentType.title as paymentTypeTitle'),
          filter: mergeFilters(
            dynamic.makeFilter('userPharmacistProfileID', userPharmacistProfileID, equals),
            dynamic.makeFilter(
              'paymentType.paymentTypeSubjectID',
              '2a4cffbe-0aab-43dd-8440-4329a24405f6',
              equals,
            ),
          ).join('&&'),
          orderBy: dynamic.orderBy('userPharmacistProfileInvoice.invoiceDate', 'desc'),
          take: 1,
        },
      }),
      transformResponse: transformResponseDynamicItemMaybe,
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getGridUserPharmacistProfilePayments: build.query<
      DynamicResult<IGridUserPharmacistProfileInvoiceDetails, { count: true }>,
      IGridUserPharmacistProfilePaymentsPaidByParams
    >({
      queryFn: async ({ skip, take, userPharmacistProfileID, search, orderBy }) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'userPharmacistProfileInvoice.invoiceDate as invoiceDate',
              'userPharmacistProfileInvoice.invoiceNumber as invoiceNumber',
              'userPharmacistProfileInvoice.userPharmacistProfileID as userPharmacistProfilePaidByID',
              'userPharmacistProfileInvoice.userPharmacistProfile.fullName as userPharmacistProfilePaidByName',
              'quantity',
              'amount',
              'paymentTypeID',
              'paymentType.title as paymentTypeTitle',
              'incomeAccount.accountCode as accountCode',
              'incomeAccount.title as incomeAccountTitle',
              'userPharmacistProfileInvoice.id as pharmacistProfileInvoiceID',
            ),
            filter: mergeFilters(
              dynamic.makeFilter(
                [
                  'userPharmacistProfileInvoice.invoiceNumber',
                  'incomeAccount.accountCode',
                  'paymentType.title',
                ],
                search,
                decoratorIsNotNullable(contains),
              ),
              dynamic.makeFilter('userPharmacistProfileID', userPharmacistProfileID, equals),
              dynamic.makeFilter('amount', 0, more),
              dynamic.makeFilter(
                'userPharmacistProfileInvoice.userPharmacistProfileID',
                userPharmacistProfileID,
                notEquals,
              ),
            ).join('&&'),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            count: true,
            skip,
            take,
          };
          const { data } = await ServiceUserPharmacistProfilePayments.getAllDynamic<
            IGridUserPharmacistProfileInvoiceDetails,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getGridUserPharmacistProfilePaymentDetails: build.query<
      DynamicResult<IGridUserPharmacistProfileInvoiceDetails, { count: true }>,
      IGridUserPharmacistProfilePaymentDetailsParams
    >({
      queryFn: async ({ skip, take, userPharmacistProfileInvoiceID, search, orderBy }) => {
        try {
          const params = {
            select: dynamic.select(
              'paymentType.title as paymentTypeTitle',
              'paymentTypeID',
              'incomeAccountID',
              'userPharmacistProfileID',
              'userPharmacistProfile.fullName as userPharmacistProfileFullName',
              'incomeAccount.accountCode as incomeAccountCode',
              'incomeAccount.title as incomeTitle',
              'quantity',
              'amount',
              'id',
              'remarks',
              'paymentType.showRemarks as showRemarks',
              'discountPercentage',
              'discountAmount',
            ),
            filter: mergeFilters(
              dynamic.makeFilter(
                [
                  'paymentType.title',
                  'userPharmacistProfile.firstName',
                  'userPharmacistProfile.lastName',
                  'incomeAccount.title',
                  'incomeAccount.accountCode',
                  'discountPercentage',
                  'discountAmount',
                ],
                search,
                decoratorIsNotNullable(contains),
              ),
              dynamic.makeFilter(
                'userPharmacistProfileInvoiceID',
                userPharmacistProfileInvoiceID,
                equals,
              ),
            ).join('&&'),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            count: true,
            skip,
            take,
          };
          const { data } = await ServiceUserPharmacistProfilePayments.getAllDynamic<
            IGridUserPharmacistProfileInvoiceDetails,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getGridPharmacistsActivities: build.query<
      DynamicResult<IGridPharmacistsActivities, { count: true }>,
      IGridPharmacistsActivitiesArgs
    >({
      query: ({
        search,
        paidByID,
        paidForID,
        invoiceNumber,
        incomeAccountTitle,
        paymentTypeTitle,
        date,
        take,
        skip,
        order,
        isActiveOnly,
      }) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS.GET_ALL_DYNAMIC,
        params: {
          filter: mergeFilters(
            dynamic.makeFilter(
              // @ts-ignore
              [
                'paymentType.title',
                'userPharmacistProfileInvoice.userPharmacistProfile.idNumber',
                'userPharmacistProfile.phoneMobile',
                'userPharmacistProfileInvoice.invoiceType.title',
                'userPharmacistProfile.email',
              ],
              search,
              decoratorIsNotNullable(contains),
            ),
            dynamic.makeFilter(
              'userPharmacistProfileInvoice.userPharmacistProfileID',
              paidByID,
              decoratorIsNotNullable(equals),
            ),
            dynamic.makeFilter(
              'userPharmacistProfileID',
              paidForID,
              decoratorIsNotNullable(equals),
            ),
            dynamic.makeFilter(
              'incomeAccount.title',
              incomeAccountTitle,
              decoratorIsNotNullable(contains),
            ),
            dynamic.makeFilter(
              'paymentType.title',
              paymentTypeTitle,
              decoratorIsNotNullable(contains),
            ),
            dynamic.makeFilter(
              'userPharmacistProfileInvoice.invoiceNumber',
              invoiceNumber,
              decoratorIsNotNullable(equals),
            ),
            dynamic.makeFilter('userPharmacistProfileInvoice.invoiceURL', null, notEquals),
            dynamic.makeFilter(
              'userPharmacistProfileInvoice.isActive',
              true,
              decoratorIsNotNullable(equals),
            ),
            isActiveOnly &&
              dynamic.makeFilter(
                'userPharmacistProfileInvoice.ignore',
                false,
                decoratorIsNotNullable(equals),
              ),
            dynamic.makeFilter(
              'userPharmacistProfileInvoice.invoiceType.step',
              1,
              decoratorIsNotNullable(more),
            ),
            dynamic.makeFilter(
              'userPharmacistProfileInvoice.invoiceDate',
              date,
              decoratorIsNotNullable(dateRange),
            ),
          ).join('&&'),
          select: dynamic.select(
            'id',
            'quantity',
            'amount',
            'fromDate',
            'toDate',
            'userPharmacistProfileInvoice.invoiceNumber',
            'userPharmacistProfileInvoice.paymentDate',
            'userPharmacistProfileInvoice.userPharmacistProfile.fullName as paidBy',
            'userPharmacistProfileInvoice.userPharmacistProfileID as paidByProfileID',
            'userPharmacistProfile.fullName as paidFor',
            'userPharmacistProfile.phoneMobile as phoneMobile',
            'userPharmacistProfile.email as email',
            'paymentType.title as paymentTypeTitle',
            'incomeAccount.title as incomeAccountTitle',
            'userPharmacistProfileInvoice.invoiceDate as invoiceDate',
            'userPharmacistProfileInvoice.invoiceType.title as invoiceTypeTitle',
            'userPharmacistProfileID',
            'userPharmacistProfileInvoice.userPharmacistProfile.idNumber as idNumber',
            'userPharmacistProfile.idNumber as paidForIdNumber',
            'userPharmacistProfileInvoice.id as pharmacistProfileInvoiceID',
          ),
          count: true,
          take,
          skip,
          orderBy: dynamic.orderBy(order.field, order.order),
        },
      }),
    }),
    getGridYearlySubscriptions: build.query<
      DynamicResult<IGridYearlySubscriptions, { count: true }>,
      IGridYearlySubscriptionsArgs
    >({
      query: ({
        search,
        paidByID,
        paidForID,
        paymentTypeID,
        year,
        take,
        skip,
        order,
        isActiveOnly,
        isEmptyOnly,
      }) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS.GET_ALL_DYNAMIC,
        params: {
          filter: mergeFilters(
            dynamic.makeFilter(
              // @ts-ignore
              [
                'paymentType.title',
                'userPharmacistProfileInvoice.userPharmacistProfile.idNumber',
                'userPharmacistProfile.phoneMobile',
                'userPharmacistProfileInvoice.invoiceType.title',
                'userPharmacistProfile.email',
              ],
              search,
              decoratorIsNotNullable(contains),
            ),
            dynamic.makeFilter(
              'userPharmacistProfileInvoice.userPharmacistProfileID',
              paidByID,
              decoratorIsNotNullable(equals),
            ),
            dynamic.makeFilter(
              'userPharmacistProfileID',
              paidForID,
              decoratorIsNotNullable(equals),
            ),
            dynamic.makeFilter('paymentTypeID', paymentTypeID, decoratorValueArray(equals)),
            //@ts-ignore
            dynamic.makeFilter('paymentType.paymentTypeSubject.rowIndex', 2, equals),
            dynamic.makeFilter('userPharmacistProfileInvoice.invoiceURL', null, notEquals),
            dynamic.makeFilter(
              'userPharmacistProfileInvoice.isActive',
              true,
              decoratorIsNotNullable(equals),
            ),
            isActiveOnly &&
              dynamic.makeFilter(
                'userPharmacistProfileInvoice.ignore',
                false,
                decoratorIsNotNullable(equals),
              ),
            isEmptyOnly &&
              mergeFilters(
                dynamic.makeFilter(['fromDate', 'toDate'], null, equals),
                'fromDate==toDate',
              ).join('||'),
            dynamic.makeFilter(
              'userPharmacistProfileInvoice.invoiceType.step',
              1,
              decoratorIsNotNullable(more),
            ),
            !!year &&
              mergeFilters(
                `(toDate >= DateTime(${format(
                  convertToDate(startOfYear(new Date(Number(year), 0, 1))),
                  'yyyy,MM,dd',
                )}))`,
                `(fromDate <= DateTime(${format(
                  convertToDate(endOfYear(new Date(Number(year), 11, 31))),
                  'yyyy,MM,dd',
                )}))`,
              ).join('&&'),
          ).join('&&'),
          select: dynamic.select(
            'id',
            'quantity',
            'amount',
            'fromDate',
            'toDate',
            'userPharmacistProfileInvoice.invoiceNumber',
            'userPharmacistProfileInvoice.userPharmacistProfile.fullName as paidBy',
            'userPharmacistProfileInvoice.userPharmacistProfileID as paidByProfileID',
            'userPharmacistProfile.fullName as paidFor',
            'userPharmacistProfile.phoneMobile as phoneMobile',
            'userPharmacistProfile.email as email',
            'paymentType.title as paymentTypeTitle',
            'incomeAccount.title as incomeAccountTitle',
            'userPharmacistProfileInvoice.invoiceDate as invoiceDate',
            'userPharmacistProfileInvoice.invoiceType.title as invoiceTypeTitle',
            'userPharmacistProfileID',
            'userPharmacistProfileInvoice.userPharmacistProfile.idNumber as idNumber',
            'userPharmacistProfile.idNumber as paidForIdNumber',
            'userPharmacistProfileInvoice.id as pharmacistProfileInvoiceID',
          ),
          count: true,
          take,
          skip,
          orderBy: dynamic.orderBy(order.field, order.order),
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchYearlySubscription: build.mutation<void, PatchPartial<IGridYearlySubscriptions, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceUserPharmacistProfilePayments.patch(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    getAvailableInvoiceNumbersSource: build.query<
      AvailableInvoiceNumbersSource[],
      { isActiveOnly: boolean }
    >({
      // @ts-ignore
      queryFn: async ({ isActiveOnly }) => {
        try {
          const {
            data: { value },
          } = await ServiceUserPharmacistProfilePayments.getAllDynamic({
            filter: mergeFilters(
              dynamic.makeFilter('userPharmacistProfileInvoice.invoiceURL', null, notEquals),
              dynamic.makeFilter(
                'userPharmacistProfileInvoice.isActive',
                true,
                decoratorIsNotNullable(equals),
              ),
              isActiveOnly &&
                dynamic.makeFilter(
                  'userPharmacistProfileInvoice.ignore',
                  false,
                  decoratorIsNotNullable(equals),
                ),
              dynamic.makeFilter(
                'userPharmacistProfileInvoice.invoiceType.step',
                1,
                decoratorIsNotNullable(more),
              ),
            ).join('&&'),
            select: dynamic.select(
              'userPharmacistProfileInvoice.invoiceNumber as title',
              'userPharmacistProfileInvoice.id as id',
            ),
            count: true,
            orderBy: dynamic.orderBy('userPharmacistProfileInvoice.invoiceNumber', 'asc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getUserPharmacistProfilePaymentInfo: build.query<
      { sumOfPaymentsAmount: number; dateOfBirth: string },
      string
    >({
      query: (userPharmacistProfileID) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('userPharmacistProfileInvoice.invoiceDate'),
          filter: mergeFilters(
            dynamic.makeFilter('userPharmacistProfileID', userPharmacistProfileID, equals),
          ).join('&&'),
          take: 1,
        },
      }),
      transformResponse: transformResponseDynamicItemMaybe,
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserPharmacistProfilePaymentName: build.query<any, void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceUserPharmacistProfiles.getAllDynamic<any>({
            select: dynamic.select('appIdentityUserID as id', 'fullName as title'),
            orderBy: dynamic.orderBy('firstName', 'desc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserPharmacistProfilePaymentTotalPaid: build.query<{ amount: number }, string>({
      query: (userPharmacistProfileID) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('amount'),
          filter: mergeFilters(
            dynamic.makeFilter('userPharmacistProfileID', userPharmacistProfileID, equals),
            dynamic.makeFilter(
              'userPharmacistProfileInvoice.userPharmacistProfileID',
              userPharmacistProfileID,
              equals,
            ),
            dynamic.makeFilter('amount', 0, more),
          ).join('&&'),
        },
      }),
      transformResponse: transformResponseDynamicItemMaybe,
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserPharmacistProfilePaymentTotalPaymentsForUser: build.query<
      { value: { amount: number }[] },
      string
    >({
      query: (userPharmacistProfileID) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICE_DETAILS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('amount'),
          filter: mergeFilters(
            dynamic.makeFilter('userPharmacistProfileID', userPharmacistProfileID, equals),
            dynamic.makeFilter('amount', 0, more),
          ).join('&&'),
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }, { type: 'InvoiceDetails' }],
    }),
    getUserPharmacistProfilePayment: build.query<UserPharmacistProfileInvoiceDetails, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceUserPharmacistProfilePayments.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postUserPharmacistProfilePayment: build.mutation<void, UserPharmacistProfileInvoiceDetails>({
      queryFn: async (customer) => {
        try {
          await ServiceUserPharmacistProfilePayments.post(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserPharmacistProfilePayment: build.mutation<
      void,
      PatchPartial<UserPharmacistProfileInvoiceDetails, 'id'>
    >({
      queryFn: async (customer) => {
        try {
          await ServiceUserPharmacistProfilePayments.patch(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    deleteUserPharmacistProfilePayment: build.mutation<
      void,
      PatchPartial<UserPharmacistProfileInvoiceDetails, 'id'>
    >({
      queryFn: async (customer) => {
        try {
          await ServiceUserPharmacistProfilePayments.delete(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    duplicateInvoiceDetail: build.mutation<
      UserPharmacistProfileInvoiceDetails,
      UserPharmacistProfileInvoiceDetailsDuplicateInput
    >({
      queryFn: async (input) => {
        try {
          return ServiceUserPharmacistProfilePayments.duplicate(input);
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
  }),
});
